import { createTheme } from "@material-ui/core/styles"

const theme = createTheme({
  navHeight: 74,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Raleway, sans-serif",
    secondary: "Raleway, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#0432A0",
      light: "#F2F4F7",
      dark: "#4C4C4C",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#f3f4f7",
      light: "#1F1D2F",
      dark: "#1F1D2F",
    },
    tertiary: {
      main: "#A88D58",
    },
    text: {
      primary: "#F2F4F7",
      secondary: "#0432A0",
      content: "#0e0b0b",
      tertiary: "#010C28",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#EEEEEE",
      gray: " #848484",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Raleway", "Open Sans", "Arial", sans-serif`,
  },
})
export default theme
